export default function Contact() {
    return (
        <main>
            <h2>Contact</h2>

            <div className="content">
                <p>This is contact, Thank you !</p>
            </div>
        </main>
    );
}